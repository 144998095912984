import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
//import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import Done from "@material-ui/icons/Done";
//import NotListedLocation from "@material-ui/icons/NotListedLocation";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddToPhotos from "@material-ui/icons/AddToPhotos";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import Toolbar from "@material-ui/core/Toolbar";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getValue } from "../../api/utils";
import TextField from "../common/TextField";
import CheckboxGroup from "../common/CheckboxGroup";
import DatePicker from "../common/DatePicker";
import Checkbox from "../common/Checkbox";
import Select from "../common/Select";
import states from "../common/states.json";
import HelpLabel from "../common/HelpLabel";
import RadioGroup from "../common/RadioGroup";
import FileInput from "../common/FileInput";
import Subquestion from "../common/Subquestion";
import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import Snackbar from "../common/Snackbar";
import BaseMapControl from "../common/map/BaseMapControl";
import { MAKE_OPTIONS } from "../../api/constants";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import { createSelector } from "../common/orm";
import { InitialLandReporting, InitialLandReportingLog } from "./models";

const getForm = InitialLandReporting.selectByUrlId();

const getLogs = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.InitialLandReportingLog.filter({ initiallandreporting_id: id }).orderBy("id", "desc").toModelArray();
    }
);

const styles = theme => ({
    deleteWidth: {
        minWidth: 32,
        width: 32,
        height: 32,
        marginRight: 8,
    },
    flex: {
        flex: 1
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eef7fa"
        }
    },
    minWidth: {
        width: "100%",
        height:"100%"
    },
    rightAlign: {
        textAlign: "center"
    },
    button: {
        marginRight: theme.spacing(2)
    },
    gutterTop: {
        textAlign: "center",
        marginTop: ".6em"
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted",
    },
});

const columnData = [
    {
        id: "actions",
        numeric: false,
        label: "Actions",
        allowSort: false,
        width: "20px"
    },
    { id: "name", numeric: false, label: "Date", allowSort: false },
    { id: "type", numeric: false, label: "Type of Action", allowSort: false },
    { id: "type", numeric: false, label: "Description/Links", allowSort: false },
    { id: "file", numeric: false, label: "Attachment", allowSort: false },
];

function formatDate(dateString) {
    if (!dateString) {
        return null;
    }
    const d = new Date(dateString);
    return d.toLocaleDateString();
}

class LandReportingForm extends Component {
    constructor(props) {
        super(props);
        this.appContainer = React.createRef();
    }
    state = {
        unsavedFields: false,
        snackbarOpen: false,
        anchorEl: null,
        setAnchorEl: null,
        submissionErrorOpen: false,
        submissionOpen: false,
        mapDialogOpen: false,
        shapefileDialogOpen: false,
        _key: null,
        dialogOpen: false,
        key: null,
        attachmentId: null
        //loading: false
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    componentDidMount() {
        const { 
            ormInitialLandReportingLoadDetail, ormInitialLandReportingLogLoadDetailChild, match, authState//, form
        } = this.props;
        
        document.title = "Initial Land Reporting Form - Lessard Sams Outdoor Heritage Council";
        // Always reload?
        //if ((!form) && authState && authState.user) {
            //ormInitialLandReportingLoadDetail(match.params.id);
        //}
        var comp = this;
        window.TRSZoom = function() {
            document.getElementById("trs_button").style.display="none";
            document.getElementById("trs_loading").style.display="inline";
            document.getElementById("trs_error").style.display="none";
            var t = document.getElementById("trs_township").value;
            var r = document.getElementById("trs_range").value;
            var s = document.getElementById("trs_section").value;
            return fetch("/trs_search/" + t + "/" + r + "/" + s, {
                method: "GET"
            }).then(function(response) {
                if (response.status >= 200 && response.status < 300) {
                    document.getElementById("trs_button").style.display="inline";
                    document.getElementById("trs_loading").style.display="none";
                    return response.json().then(data => {
                        if (data.error)
                            document.getElementById("trs_error").style.display="inline";
                        else
                            comp.leafletMap.leafletElement.setView([data.lat, data.long], 15);
                    });
                } else {
                    document.getElementById("trs_button").style.display="inline";
                    document.getElementById("trs_loading").style.display="none";
                    document.getElementById("trs_error").style.display="inline";
                }
            });
        }
        if (authState && authState.user) {
            ormInitialLandReportingLoadDetail(match.params.id);
            ormInitialLandReportingLogLoadDetailChild(match.params.id);
        }
    }

    componentDidUpdate() {
        const { 
            form, ormInitialLandReportingLoadDetail, ormInitialLandReportingLogLoadDetailChild, match, authState
        } = this.props;
        if ((!form) && authState && authState.user) {
            ormInitialLandReportingLoadDetail(match.params.id);
            ormInitialLandReportingLogLoadDetailChild(match.params.id);
        }
    }

    applyOther = (value, element) => {
        const { ormInitialLandReportingUpdateLocalOnly } = this.props;
        const { id } = this.props.form;
        ormInitialLandReportingUpdateLocalOnly({
            id: id,
            [element]: value
        });
        this.setState({ foo: "bar" });
    };

    populateResponsible = (e) => {
        const { ormInitialLandReportingUpdateLocalOnly, form } = this.props;
        const { id } = form;
        e.stopPropagation();
        ormInitialLandReportingUpdateLocalOnly({
            id: id,
            responsible_organization: form.accomplishment_info.manager_organization,
            responsible_name: form.accomplishment_info.manager_name,
            responsible_address: form.accomplishment_info.manager_address,
            responsible_city: form.accomplishment_info.manager_city,
            responsible_state: form.accomplishment_info.manager_state,
            responsible_zip: form.accomplishment_info.manager_zip,
            responsible_email: form.accomplishment_info.manager_email,
            responsible_phone: form.accomplishment_info.manager_office_number
        });
        this.form_element.setValue("responsible_organization", form.accomplishment_info.manager_organization);
        this.form_element.setValue("responsible_name", form.accomplishment_info.manager_name);
        this.form_element.setValue("responsible_address", form.accomplishment_info.manager_address);
        this.form_element.setValue("responsible_city", form.accomplishment_info.manager_city);
        this.form_element.setValue("responsible_state", form.accomplishment_info.manager_state);
        this.form_element.setValue("responsible_zip", form.accomplishment_info.manager_zip);
        this.form_element.setValue("responsible_email", form.accomplishment_info.manager_email);
        this.form_element.setValue("responsible_phone", form.accomplishment_info.manager_office_number);
        this.setState({ foo: "bar" });
    }

    populateEasement = (e) => {
        const { ormInitialLandReportingUpdateLocalOnly, form } = this.props;
        const { id } = form;
        e.stopPropagation();
        ormInitialLandReportingUpdateLocalOnly({
            id: id,
            easement_monitor_organization: form.accomplishment_info.manager_organization,
            easement_monitor_name: form.accomplishment_info.manager_name,
            easement_monitor_address: form.accomplishment_info.manager_address,
            easement_monitor_city: form.accomplishment_info.manager_city,
            easement_monitor_state: form.accomplishment_info.manager_state,
            easement_monitor_zip: form.accomplishment_info.manager_zip,
            easement_monitor_email: form.accomplishment_info.manager_email,
            easement_monitor_phone: form.accomplishment_info.manager_office_number
        });
        this.form_element.setValue("easement_monitor_organization", form.accomplishment_info.manager_organization);
        this.form_element.setValue("easement_monitor_name", form.accomplishment_info.manager_name);
        this.form_element.setValue("easement_monitor_address", form.accomplishment_info.manager_address);
        this.form_element.setValue("easement_monitor_city", form.accomplishment_info.manager_city);
        this.form_element.setValue("easement_monitor_state", form.accomplishment_info.manager_state);
        this.form_element.setValue("easement_monitor_zip", form.accomplishment_info.manager_zip);
        this.form_element.setValue("easement_monitor_email", form.accomplishment_info.manager_email);
        this.form_element.setValue("easement_monitor_phone", form.accomplishment_info.manager_office_number);
        this.setState({ foo: "bar" });
    }

    populateEasement2 = (e) => {
        const { ormInitialLandReportingUpdateLocalOnly, form } = this.props;
        const { id } = form;
        e.stopPropagation();
        ormInitialLandReportingUpdateLocalOnly({
            id: id,
            easement_monitor_organization: this.form_element.getValue("easement_holder_organization"),
            easement_monitor_name: this.form_element.getValue("easement_holder_name"),
            easement_monitor_address: this.form_element.getValue("easement_holder_address"),
            easement_monitor_city: this.form_element.getValue("easement_holder_city"),
            easement_monitor_state: this.form_element.getValue("easement_holder_state"),
            easement_monitor_zip: this.form_element.getValue("easement_holder_zip"),
            easement_monitor_email: this.form_element.getValue("easement_holder_email"),
            easement_monitor_phone: this.form_element.getValue("easement_holder_phone")
        });
        this.form_element.setValue("easement_monitor_organization", this.form_element.getValue("easement_holder_organization"));
        this.form_element.setValue("easement_monitor_name", this.form_element.getValue("easement_holder_name"));
        this.form_element.setValue("easement_monitor_address", this.form_element.getValue("easement_holder_address"));
        this.form_element.setValue("easement_monitor_city", this.form_element.getValue("easement_holder_city"));
        this.form_element.setValue("easement_monitor_state", this.form_element.getValue("easement_holder_state"));
        this.form_element.setValue("easement_monitor_zip", this.form_element.getValue("easement_holder_zip"));
        this.form_element.setValue("easement_monitor_email", this.form_element.getValue("easement_holder_email"));
        this.form_element.setValue("easement_monitor_phone", this.form_element.getValue("easement_holder_phone"));
        this.setState({ foo: "bar" });
    }

    updateLandingReport = (values/*, fromMap*/) => {
        const { ormInitialLandReportingUpdate/*2*/, form/*, history*/ } = this.props;
        const { id } = form;

        /*if (fromMap !== true) {
            this.setState({loading: true});
        }*/

        if (values.shoreline === "")
            values.shoreline = null;
        if (values.restore_total_cost === "")
            values.restore_total_cost = null;
        if (values.purchase_price === "")
            values.purchase_price = null;
        if (values.ohf_used_for_purchase === "")
            values.ohf_used_for_purchase = null;
        if (values.professional_service === "")
            values.professional_service = null;
        if (values.donations_used === "")
            values.donations_used = null;
        if (values.appraised_value === "")
            values.appraised_value = null;
        if (values.other_funds_used === "")
            values.other_funds_used = null;
        if (values.assessed_value === "")
            values.assessed_value = null;
        if (values.total_project_cost === "")
            values.total_project_cost = null;
        if (values.fees_received === "")
            values.fees_received = null;
        if (values.restored_acres === "")
            values.restored_acres = null;
        if (values.acres_wetland === "")
            values.acres_wetland = null;
        if (values.acres_forest === "")
            values.acres_forest = null;
        if (values.acres_prairie === "")
            values.acres_prairie = null;
        if (values.acres_habitat === "")
            values.acres_habitat = null;


        ormInitialLandReportingUpdate({
            id: id,
            ...values
        });

        /*const _comp = this;
        ormInitialLandReportingUpdate2({
            id: id,
            ...values
        }).then(function() { 
            if (fromMap !== true) {
                _comp.setState({loading: false});
                if (history.length <= 1)
                    window.close();
                else
                    history.goBack();
            }
        });*/
    };

    updateLandingReportShapefile = (values) => {
        const { ormInitialLandReportingUpdate, form } = this.props;
        const { id } = form;

        values["parcel_geometry"] = null;
        this.setState({shapefileDialogOpen: false});

        ormInitialLandReportingUpdate({
            id: id,
            ...values
        });

        //this.setState({_key: window.performance.now()});
    };

    setRef = ref => {
        this.leafletMap = ref;
    };

    render() {
        const { classes, form, authState, history, ormInitialLandReportingUpdate, logs, ormInitialLandReportingLogCreate, ormInitialLandReportingLogDelete, ormInitialLandReportingLogUpdate } = this.props;
        const { snackbarOpen, mapDialogOpen, shapefileDialogOpen, _key, key, dialogOpen, attachmentId, /*loading*/ } = this.state;

        if (!form) {
            return (
                <AppContainer authenticated synced={true} />
            );
        }

        const signage_erected_NO = getValue(form, "signage_erected") === "false" || getValue(form, "signage_erected") === false;
        const permanently_protected_YES = getValue(form, "permanently_protected") === "true" || getValue(form, "permanently_protected") === true;
        const is_fee = getValue(form, "parcel_type") === "Protect in Fee with PILT" || getValue(form, "parcel_type") === "Protect in Fee w/o PILT";
        const is_easement = getValue(form, "parcel_type") === "Protect in Easement";
        const is_restore = getValue(form, "parcel_type") === "Restore" || getValue(form, "parcel_type") === "Enhance";
        const difference = getValue(form, "appraised_value") !== getValue(form, "assessed_value");
        const showOther = getValue(form, "fees_received_options").indexOf("Other (Specifiy)") > -1;
        const showOther2 = getValue(form, "property_manager") === "Local Unit of Government (Explain)" || getValue(form, "property_manager") === "Other (Explain)";
        var is_admin = false;
        if (authState && authState.user && authState.user.role === "admin")
            is_admin = true;
        var is_read_only = false;
        if (authState && authState.user && authState.user.role === "readonly")
            is_read_only = true;

        var temp = parseFloat(form.ohf_used_for_purchase ? form.ohf_used_for_purchase : 0) + parseFloat(form.donations_used ? form.donations_used : 0) + parseFloat(form.other_funds_used ? form.other_funds_used : 0);
        if (temp !== form._t) {
            form._t = temp;
            if (this.form_element)
                this.form_element.setValue("_t", temp);
        }
        
        return (
            <AppContainer authenticated innerRef={this.appContainer}>
                <Grid container spacing={2}>
                    <PageHeader title={form.parcel_name} />
                    <Grid item xs={12}>
                        <Form
                            key={form.id + _key}
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={form}
                            getApi={el => (this.form_element = el)}
                            onSubmit={this.updateLandingReport}>
                            {/*onSubmit={(values, fromMap) => this.updateLandingReport(values, fromMap)}*/}
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={10} lg={8} xl={6}>
                                            <ExpansionPanel defaultExpanded={true}>
                                                <ExpansionPanelSummary
                                                    className={classes.expansionSummaryRoot}
                                                    expandIcon={<ExpandMoreIcon />}>
                                                    <Typography variant="h3">
                                                        Project Manager Contact Information
                                                    </Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6}>
                                                            <TextField label="Manager's Title" disabled={true} field="accomplishment_info.manager_title" fullWidth />
                                                            <TextField label="Manager's Name" disabled={true} field="accomplishment_info.manager_name" fullWidth />
                                                            <TextField label="Address" disabled={true} field="accomplishment_info.manager_address" fullWidth />
                                                            <TextField label="Address 2" disabled={true} field="accomplishment_info.manager_address2" fullWidth />
                                                            <TextField label="City" disabled={true} field="accomplishment_info.manager_city" fullWidth />
                                                            <TextField label="State" disabled={true} field="accomplishment_info.manager_state" fullWidth />
                                                            <TextField label="Zip" disabled={true} field="accomplishment_info.manager_zip" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField label="Organization Name" disabled={true} field="accomplishment_info.manager_organization" fullWidth />
                                                            <TextField label="Email" disabled={true} field="accomplishment_info.manager_email" fullWidth />
                                                            <TextField label="Office Number" disabled={true} field="accomplishment_info.manager_office_number" fullWidth />
                                                            <TextField label="Mobile Number" disabled={true} field="accomplishment_info.manager_mobile_number" fullWidth />
                                                            <TextField label="Fax Number" disabled={true} field="accomplishment_info.manager_fax_number" fullWidth />
                                                            <TextField label="Website" disabled={true} field="accomplishment_info.manager_website" fullWidth />
                                                            <TextField label="Legal Citation" disabled={true} field="citation" fullWidth />
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                        <Grid item xs={12} md={10} lg={8} xl={6}>
                                            <ExpansionPanel  defaultExpanded={true}>
                                                <ExpansionPanelSummary
                                                    className={classes.expansionSummaryRoot}
                                                    expandIcon={<ExpandMoreIcon />}>
                                                    <Typography variant="h3">
                                                    Parcel Information
                                                    </Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6}>
                                                            <TextField label="# of Acres" disabled={true} field="accomplishment_parcel.acres" fullWidth />
                                                            <TextField label="County" disabled={true} field="accomplishment_parcel.county" fullWidth />
                                                            <TextField label="Township" disabled={true} field="accomplishment_parcel.township" fullWidth />
                                                            <TextField label="# of Acres: Wetlands" disabled={is_read_only} useNumber field="acres_wetland" fullWidth />
                                                            <TextField label="# of Acres: Forest" disabled={is_read_only} useNumber field="acres_forest" fullWidth />
                                                            <TextField label="# of Acres: Prairie" disabled={is_read_only} useNumber field="acres_prairie" fullWidth />
                                                            <TextField label="# of Acres: Habitat" disabled={is_read_only} useNumber field="acres_habitat" fullWidth />
                                                            {(is_fee || is_easement) && (
                                                                <TextField field="parcel_pin" fullWidth disabled={is_read_only}>
                                                                    <div>
                                                                        Parcel PIN Number&nbsp;
                                                                        <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.17" target="_blank">per MS 97A.056 subd 17</a>
                                                                    </div>
                                                                </TextField>
                                                            )}
                                                            <RadioGroup
                                                                field="restoration_plan"
                                                                name="restoration_plan"
                                                                fullWidth
                                                                disabled={is_read_only}
                                                                options={[
                                                                    { label: "Yes", value: "true" },
                                                                    { label: "No", value: "false" }
                                                                ]}
                                                                alignment={true}
                                                            >
                                                                <div>
                                                                    Have you prepared and retained a Restoration and Management Plan&nbsp;
                                                                    <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.13" target="_blank">per MS 97A.056 subd 13(c)</a>?
                                                                </div>
                                                            </RadioGroup>
                                                        </Grid>
                                                        <Grid item xs={6}> 
                                                            <TextField label="Range" disabled={true} field="accomplishment_parcel.range" fullWidth />
                                                            <TextField label="Direction" disabled={true} field="accomplishment_parcel.direction" fullWidth />
                                                            <TextField label="Section" disabled={true} field="accomplishment_parcel.section" fullWidth />
                                                            <TextField label="Amount of Shoreline in Linear Feet" disabled={is_read_only} useNumber field="shoreline" fullWidth />
                                                            <TextField label="Name of Adjacent Body of Water (if applicable)"  disabled={is_read_only} field="adjacent_water" fullWidth />
                                                            <Typography style={{marginTop:18}}>
                                                                <b>Parcel Geometry</b>{is_restore && (<i> (Optional)</i>)}
                                                                    {form.parcel_geometry && (
                                                                        <Tooltip title="Geometry has been added">
                                                                            <Done style={{color:"green"}} /> 
                                                                        </Tooltip>
                                                                    )}
                                                            </Typography>
                                                            <Grid container spacing={1} style={{border: "1px dotted", padding: 16, marginLeft:24, marginTop: 8}}>
                                                                <Grid item xs={12}>
                                                                    {/*<Button onClick={() => this.setState({shapefileDialogOpen: true})} variant="contained" type="button" fullWidth color="primary">
                                                                        Upload Shapefile
                                                                    </Button>*/}
                                                                    <FileInput
                                                                        field="shapefile"
                                                                        id="shapefile"
                                                                        disabled={is_read_only}
                                                                        fullWidth
                                                                        accept=".zip"
                                                                        label={
                                                                            <HelpLabel
                                                                                inputLabel="Upload Shapefile"
                                                                                showLabel={true}
                                                                                helpText={<>
                                                                                    <Typography>
                                                                                        Please attach a .zip file that contains the .shp, .shx, .dbf, and .prj files that comprise the shapefile. 
                                                                                        The shapefile should only contain the parcel or group of parcels in this report with a defined coordinate system.
                                                                                    </Typography>
                                                                                    <Typography>
                                                                                        <b>Please make sure there are no subfolders inside of the .zip file</b>
                                                                                    </Typography>
                                                                                    <Typography>
                                                                                        <b>It is recommended to reproject the shapefile UTM 15</b>
                                                                                    </Typography>
                                                                                    </>}
                                                                            />}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography style={{textAlign:"center"}}>
                                                                        <b>OR</b>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Button disabled={is_read_only} onClick={() => {this.setState({mapDialogOpen: true});this.form_element.submitForm(true);}} variant="contained" type="button" fullWidth color="primary">
                                                                        Draw on Map
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                            <RadioGroup
                                                                field="signage_erected"
                                                                name="signage_erected"
                                                                fullWidth
                                                                disabled={is_read_only}
                                                                eventHandle={this.applyOther}
                                                                options={[
                                                                    { label: "Yes", value: "true" },
                                                                    { label: "No (Explain)", value: "false" }
                                                                ]}
                                                                alignment={true}
                                                                label={
                                                                    <div>
                                                                        Has there been signage erected at the site?&nbsp;
                                                                        <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.13" target="_blank">per MS 97A.056 subd 13(i)</a>
                                                                    </div>
                                                                }
                                                            />
                                                            {signage_erected_NO && (
                                                                <Subquestion component={
                                                                    <TextField
                                                                        field="signage_erected_explain"
                                                                        label="Explain"
                                                                        fullWidth
                                                                        multiline
                                                                        disabled={is_read_only}
                                                                        rows={4}
                                                                        rowsMax={20}
                                                                    />
                                                                } />
                                                            )}
                                                            {(is_fee || is_easement) && (
                                                                <DatePicker disabled={is_read_only} field="purchase_date" fullWidth label="Purchase Date" style={{marginTop:14,marginBottom:8}} />
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                        {(is_fee || is_easement) && (
                                            <Grid item xs={12} md={10} lg={8} xl={6}>
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary
                                                        className={classes.expansionSummaryRoot}
                                                        expandIcon={<ExpandMoreIcon />}>
                                                        <Typography variant="h3">
                                                            Annual Reporting Responsibility
                                                        </Typography>
                                                        <Button disabled={is_read_only} variant="contained" onClick={this.populateResponsible} type="button" color="primary" style={{marginTop:-8,marginLeft:24}}>
                                                            Use Project Manager Contact Info
                                                        </Button>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6}>
                                                                {is_admin && (
                                                                    <TextField label="User ID" field="responsible_user_id" fullWidth />
                                                                )}
                                                                <TextField disabled={is_read_only} label="Organization Name" field="responsible_organization" fullWidth />
                                                                <TextField disabled={is_read_only} label="Manager Name" field="responsible_name" fullWidth />
                                                                <TextField disabled={is_read_only} label="Address" field="responsible_address" fullWidth />
                                                                <TextField disabled={is_read_only} label="City" field="responsible_city" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Select disabled={is_read_only} field="responsible_state" label="State" options={states} fullWidth />
                                                                <TextField disabled={is_read_only} label="Zip" field="responsible_zip" fullWidth />
                                                                <TextField disabled={is_read_only} label="Email" field="responsible_email" fullWidth />
                                                                <TextField disabled={is_read_only} label="Phone" field="responsible_phone" fullWidth />
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </Grid>
                                        )}
                                        {(is_fee || is_easement) && (
                                            <Grid item xs={12} md={10} lg={8} xl={6}>
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary
                                                        className={classes.expansionSummaryRoot}
                                                        expandIcon={<ExpandMoreIcon />}>
                                                        <Typography variant="h3">
                                                            Costs <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.13" target="_blank">per MS 97A.056 subd 13(e)</a>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} useTextFormat field="purchase_price" fullWidth>
                                                                    <HelpLabel
                                                                        inputLabel="Acquisition Amount Paid (Purchase Price)"
                                                                        showLabel={true}
                                                                        helpText="The amount paid to seller or actual purchase price."
                                                                    />
                                                                </TextField>
                                                                <Checkbox
                                                                    field="donated"
                                                                    label="Donated?" disabled={is_read_only} 
                                                                />
                                                                <TextField disabled={is_read_only} useTextFormat field="professional_service" fullWidth>
                                                                    <HelpLabel
                                                                        inputLabel="Professional Service Costs"
                                                                        showLabel={true}
                                                                        helpText="Appraisals, legal fees, surveys, hazmat survey, recording fees"
                                                                    />
                                                                </TextField>
                                                                <TextField disabled={is_read_only} label="Appraised Value" eventHandle={this.applyOther} useTextFormat field="appraised_value" fullWidth />
                                                                <TextField disabled={is_read_only} label="Current Assessed Value" eventHandle={this.applyOther} useTextFormat field="assessed_value" fullWidth />
                                                                {difference && (
                                                                    <Subquestion component={
                                                                        <TextField
                                                                            field="difference"
                                                                            disabled={is_read_only}
                                                                            label="Explain the difference between the purchase price and appraised value"
                                                                            fullWidth
                                                                            multiline
                                                                            rows={4}
                                                                            rowsMax={20}
                                                                        />
                                                                    } />
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} eventHandle={this.applyOther} label="OHF Dollars used in Purchase of Parcel" useTextFormat field="ohf_used_for_purchase" fullWidth />
                                                                <TextField disabled={is_read_only} eventHandle={this.applyOther} useTextFormat field="donations_used" fullWidth>
                                                                    <HelpLabel
                                                                        inputLabel="Donations Used in Purchase"
                                                                        showLabel={true}
                                                                        helpText="Bargin sale, land owner takes reduced price, could be that no $ exchanges hands"
                                                                    />
                                                                </TextField>
                                                                <TextField disabled={is_read_only} eventHandle={this.applyOther} useTextFormat field="other_funds_used" fullWidth>
                                                                    <HelpLabel
                                                                        inputLabel="Other Funds used in Purchase of Parcel"
                                                                        showLabel={true}
                                                                        helpText="Other grant funds or OHF dollars if a split approp"
                                                                    />
                                                                </TextField>
                                                                <TextField disabled={is_read_only} label="Total Purchase Price of Parcel" useTextFormat field="total_project_cost" fullWidth />
                                                                <TextField disabled={true} useTextFormat field="_t" fullWidth>
                                                                    <HelpLabel
                                                                        inputLabel="Sum"
                                                                        showLabel={true}
                                                                        helpText="This is the sum of the Donation Used + OHF Dollars Used + Other Funds Used. This box and the total purchase price should match."
                                                                    />
                                                                </TextField>
                                                                <Checkbox
                                                                    field="rate_set"
                                                                    disabled={is_read_only}
                                                                    label="Rate Set – not using appraised or assessed values for fee valuation"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </Grid>
                                        )}
                                         {(is_easement) && (
                                            <Grid item xs={12} md={10} lg={8} xl={6}>
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary
                                                        className={classes.expansionSummaryRoot}
                                                        expandIcon={<ExpandMoreIcon />}>
                                                        <Typography variant="h3">
                                                            Easement Holder Information
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} label="Organization Name" field="easement_holder_organization" fullWidth />
                                                                <TextField disabled={is_read_only} label="Manager Name" field="easement_holder_name" fullWidth />
                                                                <TextField disabled={is_read_only} label="Address" field="easement_holder_address" fullWidth />
                                                                <TextField disabled={is_read_only} label="City" field="easement_holder_city" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Select disabled={is_read_only} field="easement_holder_state" label="State" options={states} fullWidth />
                                                                <TextField disabled={is_read_only} label="Zip" field="easement_holder_zip" fullWidth />
                                                                <TextField disabled={is_read_only} label="Email" field="easement_holder_email" fullWidth />
                                                                <TextField disabled={is_read_only} label="Phone" field="easement_holder_phone" fullWidth />
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </Grid>
                                        )}
                                        {(is_easement) && (
                                            <Grid item xs={12} md={10} lg={8} xl={6}>
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary
                                                        className={classes.expansionSummaryRoot}
                                                        expandIcon={<ExpandMoreIcon />}>
                                                        <Typography variant="h3">
                                                            Responsible Party for Monitoring & Enforcing Easements
                                                        </Typography>
                                                        <Button disabled={is_read_only} variant="contained" onClick={this.populateEasement} type="button" color="primary" style={{marginTop:-8,marginLeft:24}}>
                                                            Use Project Manager Contact Info
                                                        </Button>
                                                        <Button disabled={is_read_only} variant="contained" onClick={this.populateEasement2} type="button" color="primary" style={{marginTop:-8,marginLeft:24}}>
                                                            Use Easement Holder Contact Info
                                                        </Button>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} label="Organization Name" field="easement_monitor_organization" fullWidth />
                                                                <TextField disabled={is_read_only} label="Manager Name" field="easement_monitor_name" fullWidth />
                                                                <TextField disabled={is_read_only} label="Address" field="easement_monitor_address" fullWidth />
                                                                <TextField disabled={is_read_only} label="City" field="easement_monitor_city" fullWidth />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Select disabled={is_read_only} field="easement_monitor_state" label="State" options={states} fullWidth />
                                                                <TextField disabled={is_read_only} label="Zip" field="easement_monitor_zip" fullWidth />
                                                                <TextField disabled={is_read_only} label="Email" field="easement_monitor_email" fullWidth />
                                                                <TextField disabled={is_read_only} label="Phone" field="easement_monitor_phone" fullWidth />
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </Grid>
                                        )}
                                        {(is_easement) && (
                                            <Grid item xs={12} md={10} lg={8} xl={6}>
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary
                                                        className={classes.expansionSummaryRoot}
                                                        expandIcon={<ExpandMoreIcon />}>
                                                        <Typography variant="h3">
                                                            Parcel Status
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} label="Underlying Fee Owner" field="underlying_fee_owner" fullWidth />
                                                                <FileInput
                                                                    field="stewardship_plan"
                                                                    id="stewardship_plan" disabled={is_read_only}
                                                                    marginStyle={true}
                                                                    fullWidth
                                                                >
                                                                    <div>
                                                                        Long Term Stewardship Plan&nbsp;
                                                                        <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.13" target="_blank">per MS 97A.056 subd 13(6)(8)</a>
                                                                    </div>
                                                                </FileInput>
                                                                <FileInput
                                                                    field="analysis"
                                                                    id="analysis" disabled={is_read_only}
                                                                    marginStyle={true}
                                                                    fullWidth
                                                                >
                                                                    <div>
                                                                        Analysis of Operation and Maintenance Costs&nbsp;
                                                                        <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.13" target="_blank">per MS 97A.056 subd 13(g)</a>
                                                                    </div>
                                                                </FileInput>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <FileInput
                                                                    field="conservation_easement"
                                                                    id="conservation_easement" disabled={is_read_only}
                                                                    marginStyle={true}
                                                                    fullWidth
                                                                >
                                                                    <div>
                                                                        Fully Executed Conservation Easement&nbsp;
                                                                        <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.13" target="_blank">per MS 97A.056 subd 13(6)(7)</a>
                                                                    </div>
                                                                </FileInput>
                                                                <FileInput
                                                                    field="stewardship_statement"
                                                                    id="stewardship_statement" disabled={is_read_only}
                                                                    marginStyle={true}
                                                                    fullWidth
                                                                >
                                                                    <div>
                                                                        Easement Monitoring & Enforcement (Stewardship Fund Statement)&nbsp;
                                                                        <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.17" target="_blank">per MS 97A.056 subd 17</a>
                                                                    </div>
                                                                </FileInput>
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </Grid>
                                        )}
                                        {(is_fee) && (
                                            <Grid item xs={12} md={10} lg={8} xl={6}>
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary
                                                        className={classes.expansionSummaryRoot}
                                                        expandIcon={<ExpandMoreIcon />}>
                                                        <Typography variant="h3">
                                                            Parcel Status
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} useTextFormat field="fees_received" fullWidth>
                                                                    <div>
                                                                        Revenues (Fees Received)&nbsp;
                                                                        <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.8" target="_blank">per MS 97A.056 subd 8</a>
                                                                    </div>
                                                                </TextField>
                                                                <CheckboxGroup
                                                                    field="fees_received_options"
                                                                    row={true}
                                                                    disabled={is_read_only}
                                                                    eventHandle={this.applyOther}
                                                                    width={205}
                                                                    options={MAKE_OPTIONS([
                                                                        "Farm Lease",
                                                                        "Building Sale",
                                                                        "Tree Removal/Debris Scrap",
                                                                        "Other (Specifiy)"
                                                                    ])}>
                                                                </CheckboxGroup>
                                                                {showOther && (
                                                                    <Subquestion component={
                                                                        <TextField
                                                                            field="fees_received_other"
                                                                            label="Explain"
                                                                            fullWidth
                                                                        />
                                                                    } />
                                                                )}
                                                                <TextField disabled={is_read_only} label="Name of the unit area or location government unit or land manager" field="name" fullWidth />
                                                                <Select disabled={is_read_only} field="property_manager" label="Choose who this property will be managed by" 
                                                                    eventHandle={this.applyOther}
                                                                    options={MAKE_OPTIONS([
                                                                        "DNR - Wildlife Management Area",
                                                                        "DNR - Scientific and Natural Area",
                                                                        "DNR - Aquatic Management Area",
                                                                        "DNR - Forestry",
                                                                        "U.S. Fish and Wildlife Service - WPA",
                                                                        "U.S. Fish and Wildlife Service - Refuge",
                                                                        "U.S. Forest Service",
                                                                        "Local Unit of Government (Explain)",
                                                                        "Private (NGO) Property",
                                                                        "PF HMA",
                                                                        "Other (Explain)"
                                                                    ])} fullWidth />
                                                                    {showOther2 && (
                                                                        <Subquestion component={
                                                                            <TextField
                                                                                field="property_manager_explain"
                                                                                label="Explain"
                                                                                fullWidth
                                                                            />
                                                                        } />
                                                                    )}
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField disabled={is_read_only} label="Of the acquired acres, how many were restored?" useNumber field="restored_acres" fullWidth />
                                                                <DatePicker disabled={is_read_only} field="conveyance_date" fullWidth label="Date of Conveyance" style={{marginTop:14,marginBottom:8}} />
                                                                <FileInput
                                                                    field="analysis"
                                                                    id="analysis" disabled={is_read_only}
                                                                    marginStyle={true}
                                                                    fullWidth
                                                                >
                                                                    <div>
                                                                        Analysis of Operation and Maintenance Costs&nbsp;
                                                                        <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.13" target="_blank">per MS 97A.056 subd 13(g)</a>
                                                                    </div>
                                                                </FileInput>
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </Grid>
                                        )}
                                        {(is_fee || is_easement) && (
                                            <Grid item xs={12} md={10} lg={8} xl={6}>
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary
                                                        className={classes.expansionSummaryRoot}
                                                        expandIcon={<ExpandMoreIcon />}>
                                                        <Typography variant="h3">
                                                            Notice of Funding Restrictions (NOFR) <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.15" target="_blank">per MS 97A.056 subd 15(c)</a>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6}>
                                                                <RadioGroup
                                                                    field="attest_nofr"
                                                                    name="attest_nofr"
                                                                    fullWidth
                                                                    disabled={is_read_only}
                                                                    options={[
                                                                        { label: "Yes", value: "true" },
                                                                        { label: "No", value: "false" }
                                                                    ]}
                                                                    alignment={true}
                                                                >
                                                                    <HelpLabel
                                                                        inputLabel="Attest: Has the NOFR been filed on the deed?"
                                                                        showLabel={true}
                                                                        helpText="ML 2011, First Special Session, Ch. 6, Art. 1, Sec. 2, subd. 13 (c ) or MS 97A.056, subd 15(c):  This interest in real property shall be administered in accordance with the terms, conditions, and purposes of the grant agreement controlling the acquisition of the property. The interest in real property, or any portion of the interest in real property, shall not be sold, transferred, pledged, or otherwise disposed of or further encumbered without obtaining the prior written approval of the Lessard-Sams Outdoor Heritage Council or its successor. The ownership of the interest in real property transfers to the state if: (1) the holder of the interest in real property fails to comply with the terms and conditions of the grant agreement or accomplishment plan; or (2) restrictions are placed on the land that preclude its use for the intended purpose as specified in the appropriation."
                                                                    />
                                                                </RadioGroup>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <FileInput
                                                                    marginStyle={true}
                                                                    field="funding_restrictions" disabled={is_read_only}
                                                                    id="funding_restrictions"
                                                                    label="Attach NOFR here"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography className={classes.gutterTop}>
                                                                    <b>(The final report will not be approved until the NOFR has been filed)</b>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </Grid>
                                        )}
                                        {(is_fee || is_easement) && form.real_property_interest_full && form.real_property_interest_full.length > 0 && (
                                            <Grid item xs={12} md={10} lg={8} xl={6}>
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary
                                                        className={classes.expansionSummaryRoot}
                                                        expandIcon={<ExpandMoreIcon />}>
                                                        <Typography variant="h3">
                                                            Archived Annual Real Property Interest Reports
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}> 
                                                                {(() => {
                                                                    return form.real_property_interest_full.map(real_prop => {
                                                                        return (
                                                                            <Typography className={classes.gutterTop}>
                                                                                <a href={"#/real_property/" + real_prop.id} rel="noopener noreferrer">
                                                                                    {real_prop.year} ({real_prop.status})
                                                                                </a> 
                                                                            </Typography>
                                                                        )
                                                                    });
                                                                })()}
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </Grid>
                                        )}
                                        {(is_restore) && (
                                            <Grid item xs={12} md={10} lg={8} xl={6}>
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary
                                                        className={classes.expansionSummaryRoot}
                                                        expandIcon={<ExpandMoreIcon />}>
                                                        <Typography variant="h3">
                                                            Restore/Enhance Information
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6}>
                                                                <RadioGroup
                                                                    field="permanently_protected" disabled={is_read_only}
                                                                    label={
                                                                        <div>
                                                                            Is this land permanently protected&nbsp;
                                                                            <a rel="noopener noreferrer" href="https://www.revisor.mn.gov/statutes/cite/97A.056#stat.97A.056.13" target="_blank">per MS 97A.056 subd 13(f)</a>
                                                                        </div>
                                                                    }
                                                                    name="permanently_protected"
                                                                    eventHandle={this.applyOther}
                                                                    fullWidth
                                                                    options={[
                                                                        { label: "Yes (choose)", value: "true" },
                                                                        { label: "No", value: "false" }
                                                                    ]}
                                                                    alignment={true}
                                                                />
                                                                {permanently_protected_YES && (
                                                                    <Subquestion component={
                                                                        <RadioGroup
                                                                            field="permanently_protected_option"
                                                                            name="permanently_protected_option" disabled={is_read_only}
                                                                            fullWidth
                                                                            options={MAKE_OPTIONS([
                                                                                "By Conservation Easement",
                                                                                "In Public Ownership",
                                                                                "In Public Waters"
                                                                            ])}
                                                                            alignment={true}
                                                                        />
                                                                    } />
                                                                )}
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField useTextFormat field="restore_total_cost" fullWidth>
                                                                    <HelpLabel
                                                                        inputLabel="Total Cost of Restoration/Enhancement"
                                                                        showLabel={true}
                                                                        helpText="On the ground costs: supplies, materials, contracts bld removal, NOT DSS, Personnel or Travel"
                                                                    />
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </Grid>
                                        )}
                                        {(authState && authState.user && authState.user.role === "admin") && (
                                            <Grid item xs={12} md={10} lg={8} xl={6}>
                                                <ExpansionPanel>
                                                    <ExpansionPanelSummary
                                                        className={classes.expansionSummaryRoot}
                                                        expandIcon={<ExpandMoreIcon />}>
                                                        <Typography variant="h3">
                                                            Council Action
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} lg={8} xl={6}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            dialogOpen: true,
                                                                            attachmentId: null,
                                                                            key: window.performance.now()
                                                                        })
                                                                    }>
                                                                    <AddToPhotos />&nbsp;&nbsp;&nbsp;Add New Entry
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Table className={classes.table}>
                                                                    <EnhancedTableHead columnData={columnData} />
                                                                    <TableBody>
                                                                        {logs.map(n => {
                                                                            return (
                                                                                <TableRow hover key={n.id}>
                                                                                    <CustomTableCell className={classes.nowrap}>
                                                                                        <Tooltip title="Edit Entry">
                                                                                            <Button
                                                                                                color="primary"
                                                                                                onClick={() => this.setState({ dialogOpen: true, attachmentId: n }) }
                                                                                                className={classes.deleteWidth}>
                                                                                                <CreateIcon color="primary" />
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                        <Tooltip title="Delete Entry">
                                                                                            <Button color="primary" className={classes.deleteWidth} onClick={() => ormInitialLandReportingLogDelete(n.id) }>
                                                                                                <DeleteIcon color="primary" />
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                    </CustomTableCell>
                                                                                    <CustomTableCell>{formatDate(n.date)}</CustomTableCell>
                                                                                    <CustomTableCell>{n.type}</CustomTableCell>
                                                                                    <CustomTableCell>{n.description}</CustomTableCell>
                                                                                    <CustomTableCell>
                                                                                        {n.file && (
                                                                                            <a href={n.file} target="_blank" rel="noopener noreferrer">
                                                                                                Open
                                                                                            </a>
                                                                                        )}
                                                                                    </CustomTableCell>
                                                                                </TableRow>
                                                                            );
                                                                        })}
                                                                        {logs.length < 1 && (
                                                                            <TableRow>
                                                                                <CustomTableCell colSpan={4} className={classes.rightAlign}>
                                                                                    No Entries Found
                                                                                </CustomTableCell>
                                                                            </TableRow>
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} md={10} lg={8} xl={12} className={classes.rightAlign}>
                                            {/*<Button
                                                variant="contained"
                                                type="button"
                                                onClick={() => {
                                                    if (history.length <= 1)
                                                        window.close();
                                                    else
                                                        history.goBack();
                                                }}
                                                className={classes.button}>
                                                Return
                                            </Button>
                                            {loading && (
                                                <CircularProgress size={24} className={classes.button} />
                                            )}
                                            {!loading && (
                                                <Button
                                                    variant="contained"
                                                    type="submit" disabled={is_read_only}
                                                    color="primary"
                                                    className={classes.button}>
                                                    Save and Return
                                                </Button>
                                            )}*/}
                                            <Button
                                                variant="contained"
                                                type="button"
                                                onClick={() => history.goBack()}
                                                className={classes.button}>
                                                Return
                                            </Button>
                                            <Button
                                                variant="contained"
                                                type="submit" disabled={is_read_only}
                                                color="primary"
                                                onClick={() => history.goBack()}
                                                className={classes.button}>
                                                Save and Return
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="button"
                                                className={classes.button}
                                                onClick={() => window.open("/reports/land_reporting/" + form.id + "/pdf")}>
                                                Generate Report
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                </Grid>
                <Snackbar
                    handleSnackbarClose={this.handleSnackbarClose}
                    isSuccess
                    snackbarOpen={snackbarOpen}
                />
                <Dialog open={mapDialogOpen} classes={{ paper: classes.minWidth }} maxWidth="xl">
                    <DialogContent style={{margin:0,padding:0}}>
                        <BaseMapControl 
                            closeMap={() => this.setState({mapDialogOpen: false, _key: window.performance.now()})} setRef={this.setRef} 
                            ormInitialLandReportingUpdate={ormInitialLandReportingUpdate} 
                            parcel_geometry={form.parcel_geometry} 
                            reporting_id={form.id} 
                            township={form.accomplishment_parcel.township}
                            range={form.accomplishment_parcel.range}
                            authState={authState}
                            section={form.accomplishment_parcel.section}
                            is_restore={is_restore} />
                    </DialogContent>
                </Dialog>
                <Dialog open={shapefileDialogOpen}>
                    <DialogContent>
                        <Typography variant="h3" gutterBottom>
                            Shapefile Upload
                        </Typography>
                        <Typography>
                            Please attach a .zip file that contains the .shp, .shx, .dbf, and .prj files that comprise the shapefile. 
                            The shapefile should only contain the parcel or group of parcels in this report with a defined coordinate system.
                        </Typography>
                        <Typography>
                            <b>Please make sure there are no subfolders inside of the .zip file</b>
                        </Typography>
                        <Typography>
                            <b>It is recommended to reproject the shapefile UTM 15</b>
                        </Typography>
                        <Form
                            key={form.id + "S"}
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={form}
                            onSubmit={this.updateLandingReportShapefile}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <FileInput
                                        field="shapefile"
                                        id="shapefile"
                                        marginStyle={true} disabled={is_read_only}
                                        fullWidth
                                        accept=".zip"
                                        label="Shapefile"
                                    />
                                    <div style={{textAlign:"center",marginTop:16}}>
                                        <Button type="submit" disabled={is_read_only} color="primary" variant="contained" style={{marginRight:12}}>
                                            Save
                                        </Button>
                                        <Button type="button" onClick={() => this.setState({shapefileDialogOpen: false})} variant="contained" style={{marginLeft:12}}>
                                            Exit
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </Form>
                    </DialogContent>
                </Dialog>
                
                <AttachmentDialog
                    open={dialogOpen}
                    ormInitialLandReportingLogCreate={ormInitialLandReportingLogCreate}
                    ormInitialLandReportingLogUpdate={ormInitialLandReportingLogUpdate}
                    attachmentId={attachmentId}
                    returnDialog={() => this.setState({ dialogOpen: false })}
                    classes={classes}
                    form={form}
                    formkey={key}
                />
            </AppContainer>
        );
    }
}


class AttachmentDialog extends Component {
    state = {
        showLoading: false
    };

    addAttachment(values) {
        const {
            returnDialog,
            ormInitialLandReportingLogCreate,
            ormInitialLandReportingLogUpdate,
            attachmentId,
            form,
        } = this.props;

        if (attachmentId) {
            ormInitialLandReportingLogUpdate({
                id: attachmentId.id,
                ...values
            });
            returnDialog();
        } else {
            this.setState({showLoading: true});
            ormInitialLandReportingLogCreate({
                initiallandreporting_id: form.id,
                ...values
            }).then(id => {
                this.setState({showLoading: false});
                returnDialog();
            });
        }
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            date: isRequired(values.date),
            type: isRequired(values.type),
            description: isRequired(values.description),
        };

        return valObj;
    };

    render() {
        const { classes, open, returnDialog, attachmentId, formkey } = this.props;
        const { showLoading } = this.state;

        return (
            <Dialog open={open}>
                <Toolbar>
                    <Typography variant="h3" className={classes.flex}>
                        {!attachmentId ? "Add New" : "Edit"} Entry
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={() => returnDialog()}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            key={attachmentId ? attachmentId.id : formkey}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={attachmentId}
                            onSubmit={values => this.addAttachment(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <DatePicker field="date" label="Date" />
                                            <Select options={MAKE_OPTIONS([
                                                "Conveyance",
                                                "Conservation",
                                                "Double Protection",
                                                "Other"
                                            ])} field="type" label="Type of Action" fullWidth />
                                            <TextField field="description" label="Description/Link" fullWidth />
                                            <FileInput field="file" id="file" label="Upload File" fullWidth />
                                        </Grid>
                                        {showLoading && <CircularProgress size={30} style={{marginLeft:"auto", marginRight:"auto"}} />}
                                        {!showLoading && (
                                            <>
                                                <Grid item xs={6}>
                                                    <Button fullWidth onClick={() => returnDialog()}>
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button fullWidth type="submit" variant="contained" color="primary">
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

LandReportingForm = connect(
    (state, ownProps) => ({
        form: getForm(state, ownProps),
        logs: getLogs(state, ownProps),
        authState: state.auth
    }),
    {
        ...InitialLandReporting.actions,
        ...InitialLandReportingLog.actions,
    }
)(LandReportingForm);

export default withStyles(styles)(withRouter(LandReportingForm));
